@keyframes underline-animation {
  0% {
    width: 0;
    left: 50%;
  }
  50% {
    width: 120%;
    left: -10%;
  }
  100% {
    width: 100%;
    left: 0;
  }
}

.underline-animation {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.underline-animation::after {
  content: "";
  position: absolute;
  width: 0;
  height: 4px;
  bottom: -6px;
  left: 50%;
  border-radius: 80px;
  background-color: #fff;
  transition: width 0.3s ease-in-out;
}

.underline-animation:hover::after {
  animation: underline-animation 0.5s forwards;
}
